import {Plan} from '../generated/graphql';
import {callAndTextBundle, dataBundle, networkInfo} from '../utils/planInfo';

interface PlanSpecsProps {
  plan: Plan;
}

export default function PlanSpecs({
  plan,
}: PlanSpecsProps) {
  if (!plan) {
    return null;
  }
  const duration = plan.contractDuration % 12
    ? `${plan.contractDuration} maand${plan.contractDuration > 1 ? 'en' : ''}`
    : `${(plan.contractDuration as number) / 12} jaar`;

  return <div className="sf-text-sm sf-leading-6">
    <div className="sf-block">
      {callAndTextBundle(plan.callLimit || '0', plan.textLimit || '0', plan.sharedBundle || '')}
    </div>
    <div className="sf-block">
      {dataBundle(plan.dataLimit || '0')}  {networkInfo(plan.networkGeneration, plan.downloadSpeed)}
    </div>
    <div className="sf-block">
      {duration}
    </div>
  </div>;
}

import {PackageDiscount} from '../generated/graphql';

interface PackageDiscountLabelProps {
  packageDiscount: PackageDiscount;
}

export default function PackageDiscountLabel({
  packageDiscount,
}: PackageDiscountLabelProps) {
  return packageDiscount?.name && packageDiscount?.name !== 'Geen'
    ? <div className="sf-max-w-fit sf-uppercase sf-text-xs sf-px-2 sf-rounded-br-lg sf-rounded-tl-md sf-text-center sf-py-2 sf-bg-primary sf-text-white sf-font-[500]">
      Combinatievoordeel {packageDiscount.name}
    </div>
   : null;
}

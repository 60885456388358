export const translatePlanLimit = (limit: string) => {
  if (limit === 'unlimited') {
    return 'onbeperkt';
  }
  return Number(limit).toLocaleString('NL-nl');
};

export const callAndTextBundle = (callLimit: string, textLimit: string, sharedBundle: string) => {
  const translatedCallLimit = translatePlanLimit(callLimit);

  switch (sharedBundle) {
    case 'minutes_and_text': return `${translatedCallLimit} min`;
    case 'data_and_minutes_and_text': return `${translatedCallLimit} min of`;
    default: return `${translatedCallLimit} min`;
  }
};

export const dataBundle = (dataLimit: string) => {
  let convertToGB = false;
  let unit = 'MB';

  if (dataLimit === 'unlimited' || parseInt(dataLimit, 10) > 1000) {
    unit = 'GB';
  }

  if (dataLimit !== 'unlimited' && parseInt(dataLimit, 10) > 1000) {
    convertToGB = true;
  }

  const translatedDataLimit = translatePlanLimit(convertToGB ? parseInt(dataLimit, 10) / 1000 as unknown as string : dataLimit);
  return `${translatedDataLimit} ${unit}`;
};

export const networkInfo = (generation: string, downloadSpeed: number) => {
  if (!downloadSpeed) {
    return `(${generation})`;
  }

  if (downloadSpeed >= 1000) {
    return `(${generation} - ${downloadSpeed / 1000} Gbit/s)`;
  }

  return `(${generation} - ${downloadSpeed} Mbit/s)`;
};


export const bundleText = (callLimit: string, textLimit: string, dataLimit: string, sharedBundle: string) => {
  const callAndTextBundleString = callAndTextBundle(callLimit, textLimit, sharedBundle);
  const dataBundleString = dataBundle(dataLimit);

  switch (sharedBundle) {
    case 'data_and_minutes_and_text': return `${callAndTextBundleString} ${dataBundleString}`;
    default: return `${callAndTextBundleString} en ${dataBundleString}`;
  }
};

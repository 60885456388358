import {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';

import {GetPlanInfoDocument, GetPlanInfoQuery, GetPlanInfoQueryVariables, Plan, Shop, PackageOffer, PlanOffer} from '../../generated/graphql';

import InfoTab from './InfoTab';
import PricesTab from './PricesTab';
import DealsTab from './DealsTab';

interface OfferInfoProps {
  offer: PlanOffer | PackageOffer;
  position: number;
}

const OfferInfo = ({offer, position}: OfferInfoProps) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('info');

  const toggleOpen = (nextTab: string) => {
    setOpen(!(open && tab === nextTab));
    setTab(nextTab);
  };

  const [getPlanInfo, {data, loading}] = useLazyQuery<GetPlanInfoQuery, GetPlanInfoQueryVariables>(GetPlanInfoDocument, {
    variables: {
      planId: offer.plan?.id ?? 1,
    },
  });

  useEffect(() => {
    if (open) {
      getPlanInfo();
    }
  }, [open, getPlanInfo]);

  const isPlanOffer = offer.__typename === 'PlanOffer';

  return (
    <>
      <div
        className="sf-text-center sf-grid sf-grid-cols-3 sf-w-full sf-border-t sf-border-gray-300 sf-py-3 sf-text-sm sf-text-gray-400"
      >
        <button type="button" className="sf-border-r" onClick={() => toggleOpen('info')}>Meer informatie</button>
        <button type="button" className="sf-border-r" onClick={() => toggleOpen('prices')}>Prijsoverzicht</button>
        <button type="button" onClick={() => toggleOpen('deals')}>Bekijk deals</button>
      </div>
      {open &&
        <div className="sf-relative sf-border-t md:sf-p-4">
          <button
            type="button"
            className="sf-absolute sf-top-3 sf-right-4 sf-cursor-pointer sf-text-gray-400"
            onClick={() => setOpen(false)}
            aria-label="Sluit info"
          >
            ✕
          </button>
          {tab === 'info' &&
            <InfoTab
              plan={data?.plan && {...data?.plan, contractSetupFee: offer.planContractSetupFee} as Plan}
            />
          }
          {tab === 'prices' &&
            <PricesTab
              offer={offer}
              isPlanOffer={isPlanOffer}
            />
          }
          {tab === 'deals' && offer.suboffers &&
            <DealsTab
              deals={offer.suboffers}
              position={position}
              isPlanOffer={isPlanOffer}
            />
          }
        </div>
      }
    </>);
};

export default OfferInfo;

import {Shop, PackageOffer, PlanOffer} from '../../generated/graphql';
import Euro from '../Euro';
import {
  calculateTotalMonthlyCosts,
  calculateTotalOneTimeCosts,
  getMonthlyPackageCosts,
  getMonthlyPlanCosts,
  getOneTimePackageCosts,
  getOneTimePlanCosts,
  Price,
  Prices,
} from '../../utils/calculatePrices';
import Image from '../Image';

interface PriceBlockProps {
  header: string;
  prices: Price[];
  totalPrice: number;
  contractDuration?: number;
}

const PriceBlock = ({header, prices, totalPrice, contractDuration}: PriceBlockProps) => {
  return (
    <div className="sf-block">
      <span className="sf-text-sm sf-text-gray-400">{header}</span>
      {prices.map((price) =>
        <div key={price.name} className="sf-flex sf-justify-between">
          <span>{price.name}</span>
          <span><Euro amount={price.value} /></span>
        </div>)}
      {contractDuration &&
        <div className="sf-flex sf-justify-between">
          <span>Looptijd in maanden</span>
          &times; {`${contractDuration}`}
        </div>
      }
      <div className="sf-flex sf-justify-between sf-font-bold">
        <span>Totaal</span>
        <span><Euro amount={totalPrice} /></span>
      </div>
    </div>
  );
};

interface PricesBlockProps {
  shop: Shop;
  oneTimeCosts: Price[];
  monthlyCosts: Price[];
  contractDuration: number;
  totalOneTimeCosts: number;
  totalMonthlyCosts: number;
  totalPrice: number;
}

const PricesBlock = ({
  shop,
  oneTimeCosts,
  totalOneTimeCosts,
  monthlyCosts,
  totalMonthlyCosts,
  contractDuration,
  totalPrice,
}: PricesBlockProps) => {
  return (
    <div className="sf-flex-grow">
      {shop.imageUrl &&
        <Image
          src={shop.imageUrl}
          alt={shop.name}
          className="sf-h-10 sf-pb-2"
          imgSet={[
            '{imgurl}?h=40 1x',
            '{imgurl}?h=80 2x',
          ]}
        />
      }
      <PriceBlock
        header="Eenmalige kosten"
        prices={oneTimeCosts}
        totalPrice={totalOneTimeCosts}
      />
      <hr className="sf-my-4" />
      <PriceBlock
        header="Maandelijkse kosten"
        prices={monthlyCosts}
        totalPrice={totalMonthlyCosts}
        contractDuration={contractDuration}
      />
      <hr className="sf-my-4" />
      <div className="sf-flex sf-justify-between sf-font-bold">
        <span>Totaal</span>
        <span><Euro amount={totalPrice} /></span>
      </div>
      <div className="sf-flex sf-justify-between sf-font-bold">
        <span>Gemiddeld per maand</span>
        <span><Euro amount={totalPrice / contractDuration} /></span>
      </div>
    </div>
  );
};

const ProductCostExplainer = ({prices}: {prices: Prices;}) => {
  return (
    <div
      className="sf-p-4 sf-flex-grow sf-bg-gray-100 sf-text-sm sf-min-w-[20rem] sf-flex sf-flex-col sf-gap-2 md:sf-mt-16"
    >
      <span className="sf-text-gray-400">Toelichting toestelbetaling</span>
      <div className="sf-flex sf-justify-between">
        <span>Totale toestelkosten</span>
        <span><Euro amount={prices.productPrice + (prices.productMonthPrice * prices.contractDuration)} /></span>
      </div>
      <div className="sf-flex sf-justify-between">
        <span>Eenmalige betaling</span>
        <span><Euro amount={prices.productPrice} /></span>
      </div>
      <div className="sf-flex sf-justify-between">
        <span>Totaal kredietbedrag</span>
        <span><Euro amount={prices.productMonthPrice * prices.contractDuration} /></span>
      </div>
      <div className="sf-flex sf-justify-between">
        <span>Termijnbedrag</span>
        <span><Euro amount={prices.productMonthPrice} /></span>
      </div>
      <div className="sf-flex sf-justify-between">
        <span>Duur overeenkomst</span>
        {prices.contractDuration} maanden
      </div>
      <div className="sf-flex sf-justify-between">
        <span>Debetrentevoet/JKP</span>
        <span>0%</span>
      </div>
    </div>
  );
};

interface PricesTabProps {
  offer: PlanOffer | PackageOffer;
  isPlanOffer: boolean;
}

const PricesTab = ({offer, isPlanOffer}: PricesTabProps) => {
  const prices: Prices = {
    contractSetupFee: offer.planContractSetupFee as number,
    contractDuration: offer.plan?.contractDuration as number,
    planMonthPrice: (isPlanOffer ? offer.planPrice : (offer as PackageOffer).planBasePrice) as number,
    productPrice: isPlanOffer ? 0 : ((offer as PackageOffer).productPrice as number),
    productShippingPrice: offer.shippingPrice || 0,
    productMonthPrice: isPlanOffer ? 0 : ((offer as PackageOffer).productMonthPrice as number),
  };

  const oneTimeCosts = isPlanOffer ? getOneTimePlanCosts(prices) : getOneTimePackageCosts(prices);
  const totalOneTimeCosts = calculateTotalOneTimeCosts(prices);

  const monthlyCosts = isPlanOffer ? getMonthlyPlanCosts(prices) : getMonthlyPackageCosts(prices);
  const totalMonthlyCosts = calculateTotalMonthlyCosts(prices);

  const totalCosts = totalOneTimeCosts + totalMonthlyCosts;

  return (
    <div className="sf-p-4 sf-flex sf-flex-wrap sf-flex-col sf-gap-6 md:sf-flex-row md:sf-gap-10">
      <PricesBlock
        shop={offer.shop as Shop}
        oneTimeCosts={oneTimeCosts}
        monthlyCosts={monthlyCosts}
        contractDuration={prices.contractDuration}
        totalOneTimeCosts={totalOneTimeCosts}
        totalMonthlyCosts={totalMonthlyCosts}
        totalPrice={offer.totalPrice as number}
      />
      {!isPlanOffer &&
        <ProductCostExplainer
          prices={prices}
        />
      }
    </div>
  );
};

export default PricesTab;

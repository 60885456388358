import {ReactChild} from 'react';

import {Plan} from '../../generated/graphql';
import Euro from '../Euro';
import {bundleText} from '../../utils/planInfo';

interface InfoGroupProps {
  header: string;
  children: ReactChild | ReactChild[];
}

const InfoGroup = ({header, children}: InfoGroupProps) => {
  return (
    <div className="sf-flex sf-flex-col">
      <span className="sf-text-gray-400">{header}</span>
      <div className="sf-flex sf-flex-col">
        {children}
      </div>
    </div>
  );
};

interface InfoTabProps {
  plan?: Plan | undefined | null;
}

const InfoTab = ({plan}: InfoTabProps) => {
  if (!plan) {
    return null;
  }
  return <div className="sf-grid sf-grid-cols-2 sf-p-4 sf-gap-x-10 sf-gap-y-2 sf-text-sm md:sf-grid-cols-3 md:sf-p-0">
      <InfoGroup header="Bundel">
        <div>{bundleText(plan.callLimit, plan.textLimit, plan.dataLimit, plan.sharedBundle)}</div>
      </InfoGroup>
      <InfoGroup header="Tarieven buiten bundel">
        <div className="sf-block">Per minuut: <Euro amount={plan.callPrice} /></div>
        <div className="sf-block">Per SMS: <Euro amount={plan.textPrice} /></div>
        <div className="sf-block">Per MB: <Euro amount={plan.dataPrice} /></div>
      </InfoGroup>
      <InfoGroup header="Aansluitkosten">
        <Euro amount={plan.contractSetupFee} />
      </InfoGroup>
      <InfoGroup header="Informatie over het abonnement">
        {plan.usps.map((usp) => <div key={usp}>{usp}</div>)}
      </InfoGroup>
      {plan.telco.networkCoverageUrl &&
        <InfoGroup header="Netwerk">
          <div className="sf-block">{plan.telco.name}&nbsp;
            <span className="sf-text-primary">
            (<a href={plan.telco.networkCoverageUrl}>check dekking</a>)
            </span>
          </div>
        </InfoGroup>
      }
    </div>;
};

export default InfoTab;

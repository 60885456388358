import {Maybe, PackageOffer, PackageSubOffer, PlanOffer, PlanSubOffer} from '../../generated/graphql';
import Euro from '../Euro';
import ChevronRight from '../ChevronRight';
import OfferLink from '../OfferLink';
import DeliveryTime from '../DeliveryTime';
import Image from '../Image';


const dealIsPackageOffer = (deal: PlanOffer | PlanSubOffer | PackageOffer | PackageSubOffer): deal is PackageOffer => {
  return ['PackageOffer', 'PackageSubOffer'].includes(deal.__typename as string);
};

interface DealProps {
  deal: PlanOffer | PlanSubOffer | PackageOffer | PackageSubOffer;
  isPlanOffer: boolean;
  position: string;
}

const Deal = ({deal, isPlanOffer, position}: DealProps) => {

  const prices = {
    contractSetupFee: deal.planContractSetupFee || 0,
    contractDuration: deal.plan?.contractDuration || 0,
    productPrice: isPlanOffer ? 0 : (deal as PackageOffer).productPrice || 0,
  };

  return (
    <>
      <OfferLink
        href={deal.deeplink || ''}
        className="sf-cursor-pointer sf-grid sf-grid-cols-3 sf-items-center"
        onClick={() => {}}
        queryParams={{
          position,
          originalDeeplink: deal.originalDeeplink ?? '',
          zone: 'vergelijker',
          shopLogoUrl: deal.shop?.imageUrl ?? '',
        }}
      >
        <div className="sf-flex sf-flex-wrap-reverse sf-space-x-1 sf-text-gray-400">
          {deal.shop?.imageUrl &&
            <Image
              src={deal.shop.imageUrl}
              imgSet={[
                '{imgurl}?w=100 1x',
                '{imgurl}?w=200 2x',
              ]}
              alt={deal.shop.name}
              className="sf-max-w-[100px] sf-max-h-12"
            />
          }
          {dealIsPackageOffer(deal) && deal.deliveryTime &&
            <DeliveryTime deliveryTime={deal.deliveryTime} />
          }
        </div>
        <div className="sf-col-start-2 sf-text-center">
          <Euro amount={(isPlanOffer && prices.contractSetupFee) || prices.productPrice} />
        </div>
        <div className="sf-flex sf-justify-center md:sf-justify-end">
          <div className="sf-col-start-3 sf-flex sf-flex-col">
            <div><Euro amount={deal.planPrice || 0} /></div>
            <div className="sf-text-xs sf-text-gray-400 sf-hidden md:sf-block">
              <span className="sf-mr-1">gemiddelde kosten</span>
              <Euro amount={deal.totalPrice as number / (deal.plan?.contractDuration as number)} />
            </div>
          </div>
          <ChevronRight className="sf-ml-10 sf-h-8 sf-w-2 sf-hidden md:sf-block" />
        </div>
      </OfferLink>
      <hr className="sf-col-span-full sf-my-4 last:sf-hidden" />
    </>
  );
};

interface DealsTabProps {
  deals: Maybe<PlanOffer | PlanSubOffer | PackageOffer | PackageSubOffer>[];
  position: number;
  isPlanOffer: boolean;
}

const DealsTab = ({deals, position, isPlanOffer}: DealsTabProps) => {
  return (
    <div className="sf-p-4 sf-grid sf-grid-cols-3 sf-items-center">
      <span className="sf-col-start-1 sf-font-bold">Aanbieder</span>
      <span className="sf-col-start-2 sf-font-bold sf-text-center">{(isPlanOffer && 'Aansluitkosten') || 'Toestel'}</span>
      <span className="sf-col-start-3 sf-font-bold sf-text-center">Prijs p/m</span>
      <hr className="sf-my-4 sf-col-span-full" />
      <div className="sf-col-span-full">
        {deals.map((deal, i) => {
          if (!deal) {
            return null;
          }

          return <Deal key={deal.id} deal={deal} isPlanOffer={isPlanOffer} position={`${position}_${i + 1}`} />;
        })}
      </div>
    </div>
  );
};

export default DealsTab;

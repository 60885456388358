export interface Prices {
  contractSetupFee: number;
  productShippingPrice: number;
  contractDuration: number;

  planMonthPrice: number;

  productMonthPrice: number;
  productPrice: number;
}

export interface Price {
  name: string;
  value: number;
}

export const getOneTimePlanCosts = (prices: Prices): Price[] => {
  return [
    {
      name: 'Aansluitkosten',
      value: prices.contractSetupFee,
    },
  ];
};

export const getOneTimePackageCosts = (prices: Prices): Price[] => {
  return [
    {
      name: 'Toestelprijs',
      value: prices.productPrice,
    },
    {
      name: 'Thuiskopie en Porto',
      value: prices.productShippingPrice,
    },
    {
      name: 'Aansluitkosten',
      value: prices.contractSetupFee,
    },
  ];
};

export const getMonthlyPlanCosts = (prices: Prices): Price[] => {
  return [
    {
      name: 'Abonnement',
      value: prices.planMonthPrice,
    },
  ];
};

export const getMonthlyPackageCosts = (prices: Prices): Price[] => {
  return [
    {
      name: 'Abonnement',
      value: prices.planMonthPrice,
    },
    {
      name: 'Betaling telefoon',
      value: prices.productMonthPrice,
    },
  ];
};

export const calculateTotalProductMonthlyCosts = (prices: Prices): number => {
  return prices.productMonthPrice * prices.contractDuration;
};

export const calculateTotalPlanMonthlyCosts = (prices: Prices): number => {
  return prices.planMonthPrice * prices.contractDuration;
};

export const calculateTotalMonthlyCosts = (prices: Prices): number => {
  return calculateTotalPlanMonthlyCosts(prices) + calculateTotalProductMonthlyCosts(prices);
};

export const calculateTotalOneTimeCosts = (prices: Prices): number => {
  return prices.contractSetupFee + prices.productPrice + prices.productShippingPrice;
};

export const calculateTotalCosts = (prices: Prices) => {
  return calculateTotalPlanMonthlyCosts(prices) + calculateTotalProductMonthlyCosts(prices) + calculateTotalOneTimeCosts(prices);
};

export const calculateAverageMonthlyCosts = (prices: Prices) => calculateTotalCosts(prices) / prices.contractDuration;
